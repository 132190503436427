exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bot-chatbase-tsx": () => import("./../../../src/pages/bot/chatbase.tsx" /* webpackChunkName: "component---src-pages-bot-chatbase-tsx" */),
  "component---src-pages-bot-chunky-tsx": () => import("./../../../src/pages/bot/chunky.tsx" /* webpackChunkName: "component---src-pages-bot-chunky-tsx" */),
  "component---src-pages-bot-component-bot-nav-tsx": () => import("./../../../src/pages/bot/component/BotNav.tsx" /* webpackChunkName: "component---src-pages-bot-component-bot-nav-tsx" */),
  "component---src-pages-bot-dante-tsx": () => import("./../../../src/pages/bot/dante.tsx" /* webpackChunkName: "component---src-pages-bot-dante-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-category-management-tsx": () => import("./../../../src/pages/category-management.tsx" /* webpackChunkName: "component---src-pages-category-management-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hargapedia-ads-tsx": () => import("./../../../src/pages/hargapedia-ads.tsx" /* webpackChunkName: "component---src-pages-hargapedia-ads-tsx" */),
  "component---src-pages-hargapedia-promotions-tsx": () => import("./../../../src/pages/hargapedia-promotions.tsx" /* webpackChunkName: "component---src-pages-hargapedia-promotions-tsx" */),
  "component---src-pages-hargapedia-survey-tsx": () => import("./../../../src/pages/hargapedia-survey.tsx" /* webpackChunkName: "component---src-pages-hargapedia-survey-tsx" */),
  "component---src-pages-hargapedia-tsx": () => import("./../../../src/pages/hargapedia.tsx" /* webpackChunkName: "component---src-pages-hargapedia-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insight-hub-tsx": () => import("./../../../src/pages/insight-hub.tsx" /* webpackChunkName: "component---src-pages-insight-hub-tsx" */),
  "component---src-pages-insights-boost-ctr-with-hargapedia-tsx": () => import("./../../../src/pages/insights/boost-ctr-with-hargapedia.tsx" /* webpackChunkName: "component---src-pages-insights-boost-ctr-with-hargapedia-tsx" */),
  "component---src-pages-insights-changes-in-promotion-strategies-by-malaysian-retailers-tsx": () => import("./../../../src/pages/insights/changes-in-promotion-strategies-by-malaysian-retailers.tsx" /* webpackChunkName: "component---src-pages-insights-changes-in-promotion-strategies-by-malaysian-retailers-tsx" */),
  "component---src-pages-insights-explore-coffee-insights-join-hargapedia-free-survey-tsx": () => import("./../../../src/pages/insights/explore-coffee-insights-join-hargapedia-free-survey.tsx" /* webpackChunkName: "component---src-pages-insights-explore-coffee-insights-join-hargapedia-free-survey-tsx" */),
  "component---src-pages-insights-fmcg-promo-pulse-jan-dec-2022-vs-2021-tsx": () => import("./../../../src/pages/insights/fmcg-promo-pulse-jan-dec-2022-vs-2021.tsx" /* webpackChunkName: "component---src-pages-insights-fmcg-promo-pulse-jan-dec-2022-vs-2021-tsx" */),
  "component---src-pages-insights-fmcg-promo-pulse-jan-dec-2023-vs-2022-tsx": () => import("./../../../src/pages/insights/fmcg-promo-pulse-jan-dec-2023-vs-2022.tsx" /* webpackChunkName: "component---src-pages-insights-fmcg-promo-pulse-jan-dec-2023-vs-2022-tsx" */),
  "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2019-vs-2018-tsx": () => import("./../../../src/pages/insights/fmcg-promo-pulse-jan-jun-2019-vs-2018.tsx" /* webpackChunkName: "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2019-vs-2018-tsx" */),
  "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2020-vs-2019-tsx": () => import("./../../../src/pages/insights/fmcg-promo-pulse-jan-jun-2020-vs-2019.tsx" /* webpackChunkName: "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2020-vs-2019-tsx" */),
  "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2021-vs-2020-tsx": () => import("./../../../src/pages/insights/fmcg-promo-pulse-jan-jun-2021-vs-2020.tsx" /* webpackChunkName: "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2021-vs-2020-tsx" */),
  "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2022-vs-2021-tsx": () => import("./../../../src/pages/insights/fmcg-promo-pulse-jan-jun-2022-vs-2021.tsx" /* webpackChunkName: "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2022-vs-2021-tsx" */),
  "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2023-vs-2022-tsx": () => import("./../../../src/pages/insights/fmcg-promo-pulse-jan-jun-2023-vs-2022.tsx" /* webpackChunkName: "component---src-pages-insights-fmcg-promo-pulse-jan-jun-2023-vs-2022-tsx" */),
  "component---src-pages-insights-grocery-cost-increase-tsx": () => import("./../../../src/pages/insights/grocery-cost-increase.tsx" /* webpackChunkName: "component---src-pages-insights-grocery-cost-increase-tsx" */),
  "component---src-pages-insights-house-brand-vs-inflation-tsx": () => import("./../../../src/pages/insights/house-brand-vs-inflation.tsx" /* webpackChunkName: "component---src-pages-insights-house-brand-vs-inflation-tsx" */),
  "component---src-pages-insights-hp-insights-favourite-retailers-category-and-brands-tsx": () => import("./../../../src/pages/insights/hp-insights-favourite-retailers-category-and-brands.tsx" /* webpackChunkName: "component---src-pages-insights-hp-insights-favourite-retailers-category-and-brands-tsx" */),
  "component---src-pages-insights-index-tsx": () => import("./../../../src/pages/insights/index.tsx" /* webpackChunkName: "component---src-pages-insights-index-tsx" */),
  "component---src-pages-insights-is-salted-caramel-the-flavour-of-the-moment-in-malaysia-tsx": () => import("./../../../src/pages/insights/is-salted-caramel-the-flavour-of-the-moment-in-malaysia.tsx" /* webpackChunkName: "component---src-pages-insights-is-salted-caramel-the-flavour-of-the-moment-in-malaysia-tsx" */),
  "component---src-pages-insights-laundry-capsules-promotions-surge-in-malaysia-tsx": () => import("./../../../src/pages/insights/laundry-capsules-promotions-surge-in-malaysia.tsx" /* webpackChunkName: "component---src-pages-insights-laundry-capsules-promotions-surge-in-malaysia-tsx" */),
  "component---src-pages-insights-malaysia-18-month-rice-market-trends-tsx": () => import("./../../../src/pages/insights/malaysia-18-month-rice-market-trends.tsx" /* webpackChunkName: "component---src-pages-insights-malaysia-18-month-rice-market-trends-tsx" */),
  "component---src-pages-insights-navigating-sweet-change-in-the-fmcg-landscape-tsx": () => import("./../../../src/pages/insights/navigating-sweet-change-in-the-FMCG-landscape.tsx" /* webpackChunkName: "component---src-pages-insights-navigating-sweet-change-in-the-fmcg-landscape-tsx" */),
  "component---src-pages-insights-promotion-mechanics-trends-in-health-and-beauty-tsx": () => import("./../../../src/pages/insights/promotion-mechanics-trends-in-health-and-beauty.tsx" /* webpackChunkName: "component---src-pages-insights-promotion-mechanics-trends-in-health-and-beauty-tsx" */),
  "component---src-pages-insights-retail-expansion-in-malaysia-q-1-2024-tsx": () => import("./../../../src/pages/insights/retail-expansion-in-malaysia-q1-2024.tsx" /* webpackChunkName: "component---src-pages-insights-retail-expansion-in-malaysia-q-1-2024-tsx" */),
  "component---src-pages-insights-retail-promotion-trend-in-malaysia-q-1-2024-tsx": () => import("./../../../src/pages/insights/retail-promotion-trend-in-malaysia-q1-2024.tsx" /* webpackChunkName: "component---src-pages-insights-retail-promotion-trend-in-malaysia-q-1-2024-tsx" */),
  "component---src-pages-insights-retailer-growth-2023-tsx": () => import("./../../../src/pages/insights/retailer-growth-2023.tsx" /* webpackChunkName: "component---src-pages-insights-retailer-growth-2023-tsx" */),
  "component---src-pages-insights-rice-price-hike-decoding-the-market-with-mailertrack-tsx": () => import("./../../../src/pages/insights/rice-price-hike-decoding-the-market-with-mailertrack.tsx" /* webpackChunkName: "component---src-pages-insights-rice-price-hike-decoding-the-market-with-mailertrack-tsx" */),
  "component---src-pages-insights-supplier-role-in-crafting-festive-memories-tsx": () => import("./../../../src/pages/insights/supplier-role-in-crafting-festive-memories.tsx" /* webpackChunkName: "component---src-pages-insights-supplier-role-in-crafting-festive-memories-tsx" */),
  "component---src-pages-insights-trends-in-new-product-launches-q-1-2024-tsx": () => import("./../../../src/pages/insights/trends-in-new-product-launches-q1-2024.tsx" /* webpackChunkName: "component---src-pages-insights-trends-in-new-product-launches-q-1-2024-tsx" */),
  "component---src-pages-insights-unlock-insights-with-hargapedia-market-pulse-tsx": () => import("./../../../src/pages/insights/unlock-insights-with-hargapedia-market-pulse.tsx" /* webpackChunkName: "component---src-pages-insights-unlock-insights-with-hargapedia-market-pulse-tsx" */),
  "component---src-pages-insights-who-is-focusing-more-on-social-media-tsx": () => import("./../../../src/pages/insights/who-is-focusing-more-on-social-media.tsx" /* webpackChunkName: "component---src-pages-insights-who-is-focusing-more-on-social-media-tsx" */),
  "component---src-pages-instore-display-investment-tracking-tsx": () => import("./../../../src/pages/instore-display-investment-tracking.tsx" /* webpackChunkName: "component---src-pages-instore-display-investment-tracking-tsx" */),
  "component---src-pages-instore-pricecheck-tsx": () => import("./../../../src/pages/instore-pricecheck.tsx" /* webpackChunkName: "component---src-pages-instore-pricecheck-tsx" */),
  "component---src-pages-instore-tracking-tsx": () => import("./../../../src/pages/instore-tracking.tsx" /* webpackChunkName: "component---src-pages-instore-tracking-tsx" */),
  "component---src-pages-mailertrack-plus-tsx": () => import("./../../../src/pages/mailertrack-plus.tsx" /* webpackChunkName: "component---src-pages-mailertrack-plus-tsx" */),
  "component---src-pages-mailertrack-tsx": () => import("./../../../src/pages/mailertrack.tsx" /* webpackChunkName: "component---src-pages-mailertrack-tsx" */),
  "component---src-pages-market-size-report-tsx": () => import("./../../../src/pages/market-size-report.tsx" /* webpackChunkName: "component---src-pages-market-size-report-tsx" */),
  "component---src-pages-mystery-shopping-service-index-tsx": () => import("./../../../src/pages/mystery-shopping-service/index.tsx" /* webpackChunkName: "component---src-pages-mystery-shopping-service-index-tsx" */),
  "component---src-pages-mystery-shopping-tsx": () => import("./../../../src/pages/mystery-shopping.tsx" /* webpackChunkName: "component---src-pages-mystery-shopping-tsx" */),
  "component---src-pages-planogram-retail-shelf-planner-tsx": () => import("./../../../src/pages/planogram/retail-shelf-planner.tsx" /* webpackChunkName: "component---src-pages-planogram-retail-shelf-planner-tsx" */),
  "component---src-pages-planogram-training-tsx": () => import("./../../../src/pages/planogram-training.tsx" /* webpackChunkName: "component---src-pages-planogram-training-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */)
}

